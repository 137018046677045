;moment.locale('zh-cn');
moment.prototype.duplicateWeekStart = function(){
    return this.clone().startOf("week");
};
moment.prototype.getDateIndex = function(){
    return this.format("YYYYMMDD");
};
moment.prototype.getAjaxDate = function(){
    return this.format("YYYY-MM-DD");
};
function selectElementAll(el){
    var range = document.createRange();
    range.selectNodeContents(el);
    var sel = window.getSelection();
    sel.removeAllRanges();
    sel.addRange(range);
}
// Source: https://github.com/Alhadis/Snippets/blob/master/js/polyfills/IE8-child-elements.js
if(!("childElementCount" in document.documentElement)){
    Object.defineProperty(Element.prototype, "childElementCount", {
        get: function(){
            for(var c = 0, nodes = this.children, n, i = 0, l = nodes.length; i < l; ++i)
                (n = nodes[i], 1 === n.nodeType) && ++c;
            return c;
        }
    });
}
if (!Array.isArray) {
    Array.isArray = function(arg) {
        return Object.prototype.toString.call(arg) === '[object Array]';
    };
}
$.fn.serializeObject = function()
{
    var o = {};
    var a = this.serializeArray();
    $.each(a, function() {
        if (o[this.name] !== undefined) {
            if (!o[this.name].push) {
                o[this.name] = [o[this.name]];
            }
            o[this.name].push(this.value || '');
        } else {
            o[this.name] = this.value || '';
        }
    });
    return o;
};
function throttle (callback, limit, thisArg) {
    var wait = false;                 // Initially, we're not waiting
    var blocked = 0;
    return function () {              // We return a throttled function
        if (!wait) {                  // If we're not waiting
            callback.apply(thisArg, arguments);          // Execute users function
            wait = true;              // Prevent future invocations
            setTimeout(function () {  // After a period of time
                wait = false;         // And allow future invocations
            }, limit);
        }else{ // make sure events are processing
            blocked++;
            setTimeout(function(){
                if(blocked > 0){
                    blocked = 0;
                    callback.apply(thisArg, arguments);
                }
            }, limit * 2);
        }
    }
}
var Facade;
jQuery(function ($) {
    var UI = {
        weekTemplate: $('<tr></tr>'),
        calendarTemplate: $('<td><p class="calendar-header"><span class="calendar-year"></span><span class="calendar-month"></span><span class="calendar-day"></span><span class="calendar-week"></span><span class="calendar-comment"></span><span class="toolbox"><i class="fa fa-calendar-plus-o" title="添加日程"></i> <i class="fa fa-edit calendar-comment" title="日期备注"></i> <i class="fa fa-paint-brush calendar-color" title="日期高亮"></i></span></p><ol class="agenda-container"></ol></td>'),
        agendaTemplate: $('<li><div class="agenda-info"><i class="fa fa-arrows" title="移动日程"></i><span class="agenda-name" contenteditable="true"></span><span class="agenda-tag"></span><span class="toolbox agenda-toolbox"><i class="fa fa-user-plus" title="加人"></i><i class="fa fa-check agenda-done" title="日程完成"></i><i class="fa fa-level-down agenda-postpone" title="日程延期"></i><i class="fa fa-times-circle agenda-removed" title="日程取消"></i><i class="fa fa-flag-o agenda-normal" title="删除标记"></i><i class="fa fa-exclamation-circle agenda-important" title="重要日程"></i><i class="fa fa-edit agenda-comment" title="日期备注"></i><i class="fa fa-paint-brush agenda-color" title="日程高亮"></i><i class="fa fa-copy" title="复制日程"></i><i class="fa fa-trash" title="删除日程"></i><a href="/agenda/{id}/log" target="_blank"><i class="fa fa-history" title="日程操作记录"></i></a></span></div><ul class="agenda-assignments"></ul></li>'),
        assignmentTemplate: $('<li><span class="assignee"></span><span class="toolbox"><i class="fa fa-user-times" title="移除"></i></span></li>'),
        floatDashboard: $('<div id="dashboard" class="toolbox toolbox-float"><a href="/"><i class="fa fa-home" title="返回首页"></i></a><a href="/user/me" target="_blank"><i class="fa fa-user" title="修改资料"></i></a><i class="fa fa-filter" title="过滤日程"></i><i class="fa fa-reply" title="转到今天"></i><i class="fa fa-arrow-up" title="查看上月"></i><i class="fa fa-arrow-down" title="查看下月"></i><i class="fa fa-refresh" title="同步日程"></i><i class="fa status">正在加载</i><i class="fa queue" title="未保存修改数">0</i><i class="fa mode fa-unlink" title="离线状态"></i></div>'),
        userSelectPanel: $('<div id="users-panel" class="list-group"><button type="button" class="list-group-item cancel">取消</button><button type="button" class="list-group-item everyone" data-user-id="0">全体</button></div>'),
        userSelectItem: $('<button type="button" class="list-group-item"></button>'),
        userFilterItem: $('<label class="btn btn-default"><input type="checkbox" name="assignments" autocomplete="off" value="" /></label>'),
        loadingModalUI: $('<div id="modal-loading" class="modal fade in" tabindex="-1" role="dialog" aria-hidden="true"><div class="modal-backdrop fade in"></div><div class="loading-content"><i class="fa fa-spin fa-circle-o-notch"></i> <span></span></div></div>'),
        tagsFilter: false,
        assignmentsFilter: false,
        getColorPickerDefaults: function(){
            return {
                showPalette: true,
                palette: ['#C00000', '#FF0000', '#FFC000', '#FFFF00', '#92D050', '#00B050', '#00B0F0', '#D9E2F3', '#F29436'],
                showSelectionPalette: true,
                maxSelectionSize: 5,
                allowEmpty: true,
                preferredFormat: "hex",
                showInitial: true,
                showInput: true,
                chooseText: "确认",
                cancelText: "放弃"
            };
        },
        init: function (body, userID, admin) {
            this.body = body;
            this.body.append(this.floatDashboard).append(this.loadingModalUI);
            this.weekContainer = this.body.find("#calendar-container > tbody");
            this.todayCalendar = null;
            this.rowsToFit = 0;
            this.calendarContainerDOM = this.body.find("#calendar-container");
            this.calendarContainerDOM.addClass(admin ? 'admin' : 'no-admin');
            this.calendarHeaderDOM = this.body.find("#calendar-weekday-header");
            this.statusDOM = this.floatDashboard.find("i.status");
            this.updateLengthDOM = this.floatDashboard.find("i.queue");
            this.refreshDOM = this.floatDashboard.find(".fa-refresh");
            this.userPanelCloseDOM = this.userSelectPanel.find("button.cancel");
            this.filterDOM = this.body.find("#agenda-filter");
            // Bind events START
            var that = this;
            this.calendarContainerDOM.on("click", "td", function(){ // close controls
                $("li.agenda.active").removeClass("active");
            }).on("click", "li.agenda", function(event){ // open controls
                event.stopPropagation();
                var $this = $(this);
                if($this.find("span.agenda-name").attr("contenteditable") === 'false') return;
                if($this.hasClass("active")) return;
                $("li.agenda.active").removeClass("active");
                $this.addClass("active");
            }).on("click", "i.fa-calendar-plus-o", function(event){ // Add agenda in calendar
                event.stopPropagation();
                var container = $(this).closest("td");
                var calendar = container.data("object");
                var agenda = new Agenda();
                var previousAgenda = container.find("ol.agenda-container li.agenda:last-child");
                agenda.setData("新日程", calendar.date, previousAgenda.length ? previousAgenda.data("object").order + 1 : 0, "", 'normal', 0, 0, "", admin ? [] : [userID]).update(true);
                selectElementAll(agenda.ui.find("span.agenda-name").focus().get(0));
            }).on("keydown", "ol.agenda-container > li span.agenda-name", function(event){
                if(typeof event.which === 'number'){
                    if(event.which === 27){ // ESC
                        var $this = $(this);
                        var container = $this.closest("li");
                        if(container.hasClass("new")){
                            $this.blur();
                        }else{
                            var agenda = container.data("object");
                            if(typeof agenda.name === 'string') $this.text(agenda.name);
                        }
                    }else if(event.which === 13){ // ENTER
                        event.preventDefault();
                        $(this).blur();
                    }
                }
            }).on("blur", "ol.agenda-container > li span.agenda-name", function(){ // Update agenda name with new agenda insert
                var $this = $(this);
                var container = $this.closest("li");
                var agenda = container.data("object");
                var name = $this.text();
                if(name === agenda.name) return;
                agenda.name = name;
                if(container.hasClass("new")){
                    if(name.length > 0 && name !== '新日程'){ // sync insert
                        AJAX.newAgenda(agenda).done(function(json){
                            if(json.id){
                                Facade.addAgenda(agenda.setID(json.id));
                            }else{
                                UI.error("新日程返回ID为空");
                            }
                        }).fail(function () {
                            UI.error("新日程未能创建，自动重试");
                            setTimeout(function(){
                                $this.blur();
                            }, 1000);
                        });
                    }else{ //discard
                        container.remove();
                    }
                }else{
                    Facade.flagUpdated(agenda);
                }
            }).on("click", "i.fa-user-plus", function(event){ // add person
                event.stopPropagation();
                var $this = $(this);
                var agendaID = $this.closest("li").data("id");
                if(agendaID > 0) UI.showUserUI(agendaID);
            }).on("click", "i.fa-user-times", function(event){ // remove person
                event.stopPropagation();
                var $this = $(this);
                var agenda = $this.closest("li.agenda").data("object");
                if(agenda){
                    var isEveryone = $this.closest("li.everyone").length > 0;
                    if(isEveryone){
                        agenda.unsetAssignAll();
                    }else{
                        var personID = $this.closest("li.person").data("user");
                        if(personID) agenda.removeAssignment(personID);
                    }
                }
            }).on("click", "i.fa-trash", function(event){ // remove agenda
                event.stopPropagation();
                if(!confirm('是否确认删除该日程？')) return;
                var $this = $(this);
                var agenda = $this.closest("li.agenda").data("object");
                if(agenda){
                    AJAX.deleteAgenda(agenda.id).done(function(){
                        Facade.removeAgenda(agenda);
                        UI.success("日程删除完毕");
                    }).fail(function(){
                        UI.success("日程删除出错");
                    });
                }
            }).on("click", "i.fa-copy", function (event) {
                event.stopPropagation();
                var $this = $(this);
                var agenda = $this.closest("li.agenda").data("object");
                var copy = new Agenda();
                copy.setData(agenda.name, agenda.happen.clone(), agenda.order + 1, agenda.comment, agenda.tag, agenda.important, agenda.assignAll, agenda.color, agenda.assignments);
                AJAX.newAgenda(copy).done(function(json){
                    if(json.id){
                        if(json.id){
                            Facade.addAgenda(copy.setID(json.id));
                            copy.update(true);
                        }else{
                            UI.error("新日程返回ID为空");
                        }
                    }
                }).fail(function () {
                    UI.error("日程复制出错，请手动重试");
                });
            }).on("click", "i.agenda-done", function(event){ // Simple modifications
                event.stopPropagation();
                var agenda = $(this).closest("li.agenda").data("object");
                if(agenda) agenda.setTag("done");
            }).on("click", "i.agenda-postpone", function(event){
                event.stopPropagation();
                var agenda = $(this).closest("li.agenda").data("object");
                if(agenda) agenda.setTag("postpone");
            }).on("click", "i.agenda-removed", function(event){
                event.stopPropagation();
                var agenda = $(this).closest("li.agenda").data("object");
                if(agenda) agenda.setTag("removed");
            }).on("click", "i.agenda-normal", function(event){
                event.stopPropagation();
                var agenda = $(this).closest("li.agenda").data("object");
                if(agenda) agenda.setTag("normal");
            }).on("click", "i.agenda-important", function(event){
                event.stopPropagation();
                var agenda = $(this).closest("li.agenda").data("object");
                if(agenda) agenda.toggleImportant();
            }).on("click", "i.agenda-color", function(event){ // Agenda Color
                event.stopPropagation();
                var dom = $(this);
                var agendaDOM = dom.closest("li.agenda");
                var agenda = agendaDOM.data("object");
                var agendaInfo = agendaDOM.find("span.agenda-name");
                if(agenda){
                    var originalColor = agendaInfo.css("background-color");
                    var ColorPickerConfig = UI.getColorPickerDefaults();
                    ColorPickerConfig.move = function(c){
                        agendaInfo.css("background-color", c === null ? "" : c.toHexString());
                    };
                    ColorPickerConfig.hide = function(){
                        agendaInfo.css("background-color", originalColor);
                        dom.spectrum("destroy");
                    };
                    ColorPickerConfig.change = function(c){
                        originalColor = c === null ? "" : c.toHexString();
                        agenda.setColor(c === null ? "" : c.toHex());
                    };
                    ColorPickerConfig.color = agenda.color && agenda.color.length ? agenda.color : "";
                    dom.spectrum(ColorPickerConfig);
                    dom.spectrum("show");
                }
            }).on("click", "i.calendar-color", function(event){ // Calendar Color
                event.stopPropagation();
                var dom = $(this);
                var calendarDOM = dom.closest("td");
                var calendar = calendarDOM.data("object");
                var originalColor = calendarDOM.css("background-color");
                var ColorPickerConfig = UI.getColorPickerDefaults();
                ColorPickerConfig.move = function(c){
                    calendarDOM.css("background-color", c === null ? "" : c.toHexString());
                };
                ColorPickerConfig.hide = function(){
                    calendarDOM.css("background-color", originalColor);
                    dom.spectrum("destroy");
                };
                ColorPickerConfig.change = function(c){
                    originalColor = c === null ? "" : c.toHexString();
                    calendar.setColor(c === null ? "" : c.toHex());
                };
                ColorPickerConfig.color = calendar.color && calendar.color.length ? calendar.color : "";
                dom.spectrum(ColorPickerConfig);
                dom.spectrum("show");
            }).on("click", "i.calendar-comment", function(event){ // Color
                event.stopPropagation();
                var calendar = $(this).closest("td").data("object");
                if(calendar){
                    var result = prompt("请输入日期备注，最大16个字符。", calendar.comment ? calendar.comment : "");
                    if(result !== null) calendar.setComment(result);
                }
            }).on("click", "i.agenda-comment", function(event){ // Color
                event.stopPropagation();
                var agenda = $(this).closest("li.agenda").data("object");
                if(agenda){
                    var result = prompt("请输入日程备注，目前", agenda.comment ? agenda.comment : "");
                    if(result !== null) agenda.setComment(result);
                }
            });
            this.floatDashboard.find("i.fa-filter").click(function(){
                that.filterDOM.modal('show');
            }).end().find("i.fa-reply").click(function(){
                if(typeof UI.scrollToToday === "function") UI.scrollToToday();
            }).end().find("i.fa-arrow-up").click(function(){
                Facade.addWeeks(-4);
            }).end().find("i.fa-arrow-down").click(function(){
                Facade.addWeeks(4);
            });
            // Agenda assignment add layer
            this.userPanelCloseDOM.click(function(){
                $(this).closest("div").removeAttr("data-for").hide();
            });
            this.userSelectPanel.find("button.everyone").click(function(){
                var $this = $(this);
                var agendaID = $this.closest("div").attr("data-for");
                if(agendaID){
                    var agenda = Facade.getAgenda(agendaID);
                    if(agenda) agenda.setAssignAll();
                }
                that.userPanelCloseDOM.click();
            });
            this.userSelectPanel.on("click", "button.person", function(ev){
                ev.preventDefault();
                var $this = $(this);
                var agendaID = $this.closest("div").attr("data-for");
                if(agendaID){
                    var agenda = Facade.getAgenda(agendaID);
                    if(agenda) agenda.addAssignment($this.data("user-id"));
                }
                that.userPanelCloseDOM.click();
            });
            this.filterDOM.find("form").submit(function(ev){
                ev.preventDefault();
                var filters = $(this).serializeObject();
                if(typeof filters['tags'] === "undefined"){
                    that.tagsFilter = false;
                }else if(typeof filters['tags'] === "string"){
                    that.tagsFilter = [filters['tags']];
                }else if(Array.isArray(filters['tags'])){
                    that.tagsFilter = filters['tags'].slice(0);
                }
                if(typeof filters['assignments'] === "undefined"){
                    that.assignmentsFilter = false;
                }else if(typeof filters['assignments'] === "string"){
                    that.assignmentsFilter = [parseInt(filters.assignments)];
                }else if(Array.isArray(filters['assignments'])){
                    that.assignmentsFilter = $.map(filters.assignments, function(id){
                        return parseInt(id);
                    });
                }
                if(that.tagsFilter || that.assignmentsFilter){
                    that.floatDashboard.find("i.fa-filter").addClass("filter-on");
                }else{
                    that.floatDashboard.find("i.fa-filter").removeClass("filter-on");
                }
                that.filterAgenda(false);
                that.filterDOM.modal('hide');
            });
            this.filterDOM.find("button.btn-warning").click(function(){
                that.filterDOM.find("div.modal-body label.active").click();
            });
            // Bind Events End
            this.success("UI初始化完成");
        },
        success: function(message){
            this.info(message, 'success');
        },
        error: function(message){
            this.info(message, 'error');
            this.floatDashboard.addClass("strong-notify");
            var that = this;
            setTimeout(function(){
                that.floatDashboard.removeClass("strong-notify");
            }, 3000);
        },
        info: function(message, type){
            type = type || false;
            this.statusDOM.text(message);
            console.log(message);
            if(type) this.statusDOM.removeClass("success error").addClass(type);
        },
        loading: function(run){
            if(run){
                this.refreshDOM.addClass("fa-spin"); // event handler will test fa-spin for exit
            }else{
                this.refreshDOM.removeClass("fa-spin");
            }
        },
        modalLoading: function(text){
            text = text || false;
            if(typeof text === 'string'){
                this.loadingModalUI.find("span").text(text).end().show();
                this.body.addClass("modal-open");
            }else{
                this.loadingModalUI.hide();
                this.body.removeClass("modal-open");
            }
        },
        onlineMode: function(bool){
            var dom = this.floatDashboard.find("i.mode");
            if(bool){
                dom.removeClass("fa-unlink").addClass("fa-link").attr("title", "在线状态");
            }else{
                dom.removeClass("fa-link").addClass("fa-unlink").attr("title", "离线状态");
            }
        },
        unsavedAgendas: function(quantity){
            this.updateLengthDOM.text(quantity);
        },
        windowHeight: function(){
            return Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
        },
        fitRows: function () {
            var rows = this.calendarContainerDOM.find("tr");
            var rowsToFit = Math.ceil((this.windowHeight() - this.calendarHeaderDOM.height()) / rows.height());
            if(rowsToFit != this.rowsToFit){
                this.success("窗口大小显示" + rowsToFit + "周");
                if(rowsToFit > rows.length){
                    var divideAmount = Math.ceil((rowsToFit - rows.length) / 2);
                    Facade.addWeeks(divideAmount);
                    Facade.addWeeks(-divideAmount);
                }
            }
            this.rowsToFit = rowsToFit;
            return this.rowsToFit;
        },
        lastScrollPosition: 0,
        scrollTolerance: 0.15,
        scroll: function(position){
            this.lastScrollPosition = position;
            this.body.scrollTop(position);
        },
        appendRows: function (scrollDirection) {
            if(typeof scrollDirection == 'undefined') scrollDirection = 0;
            var scroll = this.body.scrollTop();
            var height = this.body.height();
            var upperLimit = height * this.scrollTolerance;
            var lowerLimit = height - this.windowHeight();
            if(scrollDirection != 0) console.log(scrollDirection, scroll, upperLimit, lowerLimit);
            if(scroll < upperLimit && (scrollDirection === 1 || scroll < this.lastScrollPosition)){
                Facade.addWeeks(-this.rowsToFit);
            }else if(scroll > lowerLimit && (scrollDirection === -1 || scroll > this.lastScrollPosition)){
                Facade.addWeeks(this.rowsToFit);
            }
            this.lastScrollPosition = scroll;
        },
        firstCalendarDOM: function(){
            return this.calendarContainerDOM.find("tr:first-child td:first-child");
        },
        lastCalendarDOM: function(){
            return this.calendarContainerDOM.find("tr:last-child td:last-child");
        },
        createUserUI: function(users){
            this.userSelectPanel.find("div.person").remove();
            var that = this;
            var filterContainer = $("#checkAssignments");
            $.each(users, function (index, item) {
                that.userSelectPanel.append(that.userSelectItem.clone().text(item.name).attr("data-user-id", item.id).addClass('person'));
                filterContainer.append(that.userFilterItem.clone().find("input").val(item.id).end().append(item.name));
            });
            this.body.append(this.userSelectPanel);
        },
        showUserUI: function(agendaID){
            this.userSelectPanel.attr("data-for", agendaID).fadeIn();
        },
        filterAgenda: function(unchanged){
            if(typeof unchanged == 'undefined') unchanged = true;
            console.log(unchanged, this.tagsFilter, this.assignmentsFilter);
            if(unchanged && !this.tagsFilter && !this.assignmentsFilter) return;
            var that = this;
            this.calendarContainerDOM.find("li.agenda").each(function(){
                var $this = $(this);
                if(!that.tagsFilter && !that.assignmentsFilter) $this.show();
                var agenda = $this.data("object");
                if(that.tagsFilter && that.tagsFilter.indexOf(agenda.tag) === -1){
                    $this.hide();
                    return;
                }else{
                    $this.show();
                }
                if(that.assignmentsFilter && !agenda.assignAll){
                    var found = false;
                    $.each(agenda.assignments, function(index, item){
                        if(found) return;
                        if(that.assignmentsFilter.indexOf(item) >= 0) found = true;
                    });
                    found ? $this.show() : $this.hide();
                }
            });
        }
    };
    var AJAX = {
        pointer: {from: null, to: null},
        requestDefault: function(){
            return {
                dataType: "json",
                timeout: 30000,
                cache: false
            };
        },
        init: function () {
            $(document).ajaxStart(function(){
                UI.loading(true);
            }).ajaxStop(function(){
                UI.loading(false);
            }).ajaxError(function (event, xhr, setting, error) {
                console.log(event, setting, error, xhr.responseText);
                UI.error("网络异常" + xhr.status);
                UI.onlineMode(false);
                // clear locks, callback etc
            }).ajaxSuccess(function (event, xhr, options, data) {
                console.log(data/*, xhr.responseText*/);
                UI.onlineMode(true);
            });
            var that = this;
            UI.success("正在下载");
            this.syncUser().done(function(){
                that.syncCalendar().done(function(){
                    that.agendaLoop();
                    that.updateLoop();
                    UI.success("就绪");
                });
            });
            // load calendars

        },
        // SERIALIZED METHODS
        agendaLoopInterval: 2000,
        updateLoopInterval: 3000,
        agendaLoopHandle: null,
        updateLoopHandle: null,
        agendaLoop: function(){
            var firstMoment = UI.firstCalendarDOM().data("object").date.clone();
            var lastMoment = UI.lastCalendarDOM().data("object").date.clone();
            var jqXHR;
            if(!this.pointer.from || !this.pointer.to){
                jqXHR = this.loadAgenda(firstMoment.getAjaxDate(), lastMoment.getAjaxDate());
            }else{
                if(firstMoment.isBefore(this.pointer.from)){
                    jqXHR = this.loadAgenda(firstMoment.getAjaxDate(), this.pointer.from.getAjaxDate());
                }
                if(lastMoment.isAfter(this.pointer.to)){
                    jqXHR = this.loadAgenda(this.pointer.to.getAjaxDate(), lastMoment.getAjaxDate());
                }
            }
            if(jqXHR){
                var that = this;
                jqXHR.always(function(){
                    console.log("Start next sequence");
                    that.agendaLoopHandle = setTimeout(that.agendaLoop.bind(that), that.agendaLoopInterval);
                });
            }else{
                //console.log("Nothing to fetch");
                this.agendaLoopHandle = setTimeout(this.agendaLoop.bind(this), this.agendaLoopInterval);
            }
        },
        updateLoop: function(){
            if(Facade.agendaToUpdate.length){
                var list = Facade.agendaToUpdate.splice(0, Facade.agendaToUpdate.length); // Agenda[]
                var agendas = [];
                var that = this;
                $.each(list, function(index, item){
                    agendas.push(item.getRaw());
                });
                this.updateAgenda(agendas).fail(function(){
                    Facade.flagUpdated(list); // restore stack for retry
                }).always(function(){
                    UI.unsavedAgendas(Facade.agendaToUpdate.length);
                    that.updateLoopHandle = setTimeout(that.updateLoop.bind(that), that.updateLoopInterval);
                });
            }else{
                //console.log("Nothing to update");
                this.updateLoopHandle = setTimeout(this.updateLoop.bind(this), this.updateLoopInterval);
            }
        },
        // GETTERS
        syncUser: function(){
            var request = this.requestDefault();
            request.method = 'GET';
            request.success = function (json) {
                Facade.userIndex = {};
                $.each(json, function(){
                    Facade.userIndex[this.id] = new User(this.id, this.name);
                });
                UI.createUserUI(json);
                UI.success("同步用户数据");
            };
            return $.ajax('/user', request);
        },
        syncCalendar: function(){
            var request = this.requestDefault();
            request.method = 'GET';
            request.success = function (json) {
                $.each(Facade.calendarIndex, function(){
                    this.reset();
                });
                $.each(json, function(){
                    var calendar = Facade.getCalendar(this.date);
                    calendar.color = this.color;
                    calendar.comment = this.comment;
                    calendar.update();
                });
                UI.success("同步日期数据");
            };
            return $.ajax('/calendar/list', request);
        },
        loadAgenda: function(from, to){
            console.log("Fetching agenda from " + from + " to " + to);
            UI.modalLoading('正在加载日程');
            var request = this.requestDefault();
            var that = this;
            if(moment.isMoment(from)) from = from.getAjaxDate();
            if(moment.isMoment(to)) to = to.getAjaxDate();
            request.method = 'GET';
            request.data = {from: from, to: to};
            request.success = function (json) {
                that.pointer.from = moment.isMoment(that.pointer.from) ? moment.min(that.pointer.from, new moment(from)) : new moment(from);
                that.pointer.to = moment.isMoment(that.pointer.to) ? moment.max(that.pointer.to, new moment(to)) : new moment(to);
                $.each(json, function(){
                    var agenda = Facade.getAgenda(this.id);
                    agenda.setData(this.name, this.happen, this.order, this.comment, this.tag, this.important, this.assignAll, this.color, this.assignments);
                    agenda.update(true);
                });
                UI.filterAgenda(true);
            };
            return $.ajax('/agenda/query', request).always(UI.modalLoading.bind(UI));
        },
        // SETTERS
        upsertCalendar: function(calendar){
            var request = this.requestDefault();
            request.method = 'POST';
            request.dataType = 'text';
            request.data = calendar.getRaw();
            return $.ajax('/calendar/' + calendar.date.getAjaxDate(), request);
        },
        newAgenda: function(agenda){
            if(typeof agenda.getRaw == 'function') agenda = agenda.getRaw();
            console.log("POST new agenda ", agenda);
            var request = this.requestDefault();
            request.method = 'POST';
            request.data = $.param(agenda);
            console.log(request);
            return $.ajax('/agenda', request);
        },
        updateAgenda: function(agendas){
            var request = this.requestDefault();
            request.method = 'POST';
            request.dataType = 'text';
            request.data = {agenda: agendas};
            return $.ajax('/agenda/update', request);
        },
        deleteAgenda: function(agendaID){
            var request = this.requestDefault();
            request.method = 'DELETE';
            request.dataType = 'text';
            return $.ajax("/agenda/" + agendaID, request);
        }
    };
    Facade = {
        ui: UI,
        today: (new moment()).startOf("day"),
        deadline: false,
        deadlineIf: 100,
        user: null,
        admin: false,
        weeks: [],
        userIndex: {},
        calendarIndex: {},
        agendaIndex: {},
        agendaToUpdate: [],
        init: function(deadline, UID, Admin){
            var that = this;
            this.user = UID;
            this.admin = Admin;
            if(deadline && moment.isMoment(deadline)) this.deadline = deadline;
            this.ui.init($("body"), this.user, this.admin);
            this.addWeek(this.today.duplicateWeekStart());
            this.ui.fitRows();
            $(window).resize(throttle(function(){
                UI.fitRows.apply(UI);
            }, 800));
            var todayCalendar = $("td.today");
            this.ui.scrollToToday = function(){
                that.ui.scroll(todayCalendar.offset().top - that.ui.windowHeight() / 2);
            };
            this.ui.scrollToToday();
            $(window).scroll(throttle(function () {
                if(that.ui.body.hasClass("modal-open")) return;
                that.ui.appendRows.apply(that.ui);
            }, 1500)).mousewheel(throttle(function (event) {
                if(that.ui.body.hasClass("modal-open")) return; // do nothing on filter is shown
                if(typeof event === "object" && typeof event.deltaY === "number"){
                    that.ui.appendRows.apply(that.ui, [event.deltaY]);
                }
            }, 4000));
            AJAX.init();
        },
        addWeek: function(date, direction){
            var week = new Week(date, direction);
            this.weeks.push(week);
            return week;
        },
        addWeeks: function(amount){
            if(!amount) return;
            var fromDate = this.ui.calendarContainerDOM.find(amount > 0 ? "tr:last-child" : "tr:first-child").data("startDate").clone();
            var dateMethod = amount > 0 ? "add" : "subtract";
            var insertMethod = amount > 0 ? "append" : "prepend";
            for(var i = 1; i <= Math.abs(amount); i++){
                this.addWeek(fromDate.clone()[dateMethod](i, "weeks"), insertMethod);
            }
        },
        getCalendar: function(date){
            var dateIndex = moment.isMoment(date) ? date.getDateIndex() : date.replace(/-/g, '');
            var calendar = this.calendarIndex[dateIndex];
            if(!calendar){
                calendar = new Calendar(date);
                this.calendarIndex[dateIndex] = calendar;
            }
            return calendar;
        },
        addAgenda: function (agenda) {
            this.agendaIndex[agenda.id] = agenda;
        },
        flagUpdated: function (agenda) {
            var that = this;
            if(Array.isArray(agenda)){ // Mass assignment
                $.each(agenda, function(){
                    that.flagUpdated(this);
                });
            }
            if(typeof agenda != "object") agenda = this.getAgenda(agenda); // GET by ID
            if(!agenda.id) return;
            if(this.agendaToUpdate.indexOf(agenda) === -1) this.agendaToUpdate.push(agenda);
            UI.unsavedAgendas(this.agendaToUpdate.length);
        },
        getAgenda: function (id) {
            var agenda = this.agendaIndex[id];
            if(typeof agenda === "undefined"){
                agenda = new Agenda(id);
                this.addAgenda(agenda);
            }
            return agenda;
        },
        removeAgenda: function (agenda) {
            agenda.ui.remove();
            delete this.agendaIndex[typeof agenda.id === 'undefined' ? agenda : agenda.id];
        },
        getUserName: function (id) {
            return typeof this.userIndex[id] == 'object' ? this.userIndex[id]['name'] : '';
        }
    };
    var User = function(id, name){
        this.id = id;
        this.name = name;
    };
    var Week = function(startDate, direction){
        direction = direction || 'append';
        this.calendar = {};
        this.ui = UI.weekTemplate.clone().attr("data-week", startDate.format('ggggWW')).data("startDate", startDate);
        for(var weekday = 0; weekday < 7; weekday++){
            var date = startDate.clone().add(weekday, 'days');
            var calendar = Facade.getCalendar(date);
            this.ui.append(calendar.ui);
            this.calendar[calendar.date.getDateIndex()] = calendar;
        }
        UI.weekContainer[direction](this.ui);
    };
    var Calendar = function(date, color, comment){
        this.date = moment.isMoment(date) ? date : new moment(date);
        this.color = color || false;
        this.comment = comment || false;
        var dayOfMonth = this.date.date();
        var monthOfYear = this.date.month() + 1;
        this.ui = UI.calendarTemplate.clone().attr("data-date", this.date.getDateIndex()).data("date-ajax", this.date.getAjaxDate()).data("object", this).addClass(monthOfYear % 2 ? 'month-even' : 'month-odd').
            find("p.calendar-header").attr('data-month-day', this.date.format("MMDD")).end().
            find("span.calendar-year").text(this.date.format("YYYY")).end().
            find("span.calendar-month").text(monthOfYear).end().
            find("span.calendar-day").text(dayOfMonth).end().
            find("span.calendar-week").text(this.date.format('ddd')).end().
            find("ol.agenda-container").sortable({
                group: "calendar",
                handle: ".fa-arrows",
                draggable: "li.agenda",
                onAdd: function (evt) {
                    var $this = $(evt.item);
                    var calendar = $(evt.target).closest("td").data("object");
                    var agenda = $this.data("object");
                    var $prev = $this.prev();
                    if(typeof calendar === "object" && typeof agenda === "object"){
                        agenda.move(calendar.date, $prev.length ? $prev.data("object").order + 1 : 0);
                    }
                },
                onUpdate: function(evt){
                    var $this = $(evt.item);
                    var agenda = $this.data("object");
                    var $prev = $this.prev();
                    if(typeof agenda === "object"){
                        agenda.move(null, $prev.length ? $prev.data("object").order + 1 : 0);
                    }
                }
            }).end();
        if(!UI.todayCalendar && this.date.getDateIndex() == Facade.today.getDateIndex()) this.ui.addClass("today");
        if(Facade.today.isAfter(this.date)) this.ui.addClass("elapsed");
        if(moment.isMoment(Facade.deadline) && typeof Facade.deadlineIf === 'number'){
            var DeadlineLeft = Facade.deadline.diff(this.date, 'days');
            if(DeadlineLeft >= 0 && DeadlineLeft <= Facade.deadlineIf) $('<span class="badge">' + DeadlineLeft + '</span>').insertBefore(this.ui.find("span.calendar-comment"));
        }
        this.update(true);
    };
    Calendar.prototype.update = function(initial){
        if(typeof initial === "undefined") initial = false;
        if(initial && !this.color && !this.comment) return;
        this.ui.css("background-color", this.color ? "#" + this.color : "");
        this.ui.find("span.calendar-comment").text(this.comment ? this.comment : "");
    };
    Calendar.prototype.reset = function(){
        if(this.color || this.comment){
            this.color = this.comment = false;
            this.update(false);
        }
    };
    Calendar.prototype.getRaw = function(){
        return {
            color: this.color ? this.color : "",
            comment: this.comment ? this.comment : ""
        };
    };
    Calendar.prototype.setColor = function(color){
        if(color != this.color){
            this.color = color;
            var that = this;
            AJAX.upsertCalendar(this).done(function(){
                that.update(false);
            }).fail(function(){
                UI.error("高亮颜色保存失败");
            });
        }
        return this;
    };
    Calendar.prototype.setComment = function(comment){
        if(comment != this.comment){
            this.comment = comment;
            var that = this;
            AJAX.upsertCalendar(this).done(function(){
                that.update(false);
            }).fail(function(){
                UI.error("日期备注保存失败");
            });
        }
        return this;
    };
    var Agenda = function(id){
        this.id = id || 0;
        this.ui = UI.agendaTemplate.clone().data("object", this);
        this.assignments = [];
        if(this.id > 0){
            this.setID(id);
        }else{
            this.ui.addClass("new");
        }
    };
    Agenda.prototype.setID = function(id){
        this.id = id;
        if(id > 0) this.ui.attr("data-id", id).removeClass("new").addClass("agenda").find(".toolbox a").attr("href", function(index, oldHref){
            return oldHref.replace(/\{id\}/g, id);
        });
        return this;
    };
    Agenda.prototype.setData = function(name, happen, order, comment, tag, important, assignAll, color, assignments){
        order = order || 0;
        comment = comment || "";
        color = color || false;
        assignments = assignments || [];
        this.name = name;
        this.happen = moment.isMoment(happen) ? happen : new moment(happen);
        this.order = parseInt(order);
        this.comment = comment.length ? comment : "";
        this.tag = tag || 'normal';
        this.important = important || false;
        this.assignAll = assignAll || false;
        this.color = color.length ? color : false;
        if(this.assignAll){
            this.assignments = [];
        }else if(assignments.length && typeof assignments[0] == 'object'){
            this.assignments = [];
            var that = this;
            $.each(assignments, function(index, item){
                that.assignments.push(item.id);
            });
        }else{
            this.assignments = assignments;
        }
        return this;
    };
    Agenda.prototype.update = function(initial, doMove){
        initial = initial || false;
        doMove = doMove || (initial ? true : false);
        var tagDOM = this.ui.attr("data-order", this.order).attr("data-tag", this.tag).find("span.agenda-name").text(this.name).end().find("span.agenda-tag").empty();
        if(!initial){
            if(!this.comment.length) this.ui.find("span.agenda-name").removeAttr("title");
            if(!this.color) this.ui.find("span.agenda-name").css("background-color", "");
        }
        this.ui.find("span.agenda-name").attr("contenteditable", this.isManaged() ? "true" : "false");
        if(this.comment.length) this.ui.find("span.agenda-name").attr("title", this.comment);
        if(this.color) this.ui.find("span.agenda-name").css("background-color", "#" + this.color);
        if(this.tag === 'done'){
            tagDOM.append($('<i class="fa fa-check" title="已完成"></i>'));
        }else if(this.tag == "postpone"){
            tagDOM.append($('<i class="fa fa-level-down" title="预计延期"></i>'));
        }else if(this.tag == "removed"){
            tagDOM.append($('<i class="fa fa-times-circle" title="预计取消"></i>'));
        }
        if(this.important) tagDOM.append($('<i class="fa fa-exclamation" title="重要"></i>'));
        var assignmentDOM = this.ui.find("ul.agenda-assignments").empty();
        if(this.assignAll){
            assignmentDOM.append(UI.assignmentTemplate.clone().addClass("everyone").find("span.assignee").text("全体").end());
        }else{
            $.each(this.assignments, function(index, item){
                var username = Facade.getUserName(item);
                if(username.length) assignmentDOM.append(UI.assignmentTemplate.clone().addClass("person").attr("data-user", item).find("span.assignee").text(username).end());
            });
        }
        if(doMove){
            var container = Facade.getCalendar(this.happen).ui.find("ol.agenda-container");
            var existItems = container.find("li.agenda");
            var position = null;
            for(var i = 0; i < existItems.length; i++){
                var calendar = existItems.eq(i);
                if(parseInt(calendar.data("order")) > this.order){
                    position = i;
                    this.ui.insertBefore(calendar);
                }
            }
        }
        if(position === null) this.ui.appendTo(container);
        return this;
    };
    Agenda.prototype.getRaw = function(){
        var data = {
            name: this.name,
            happen: this.happen.getAjaxDate(),
            order: this.order,
            comment: this.comment,
            tag: this.tag,
            assignAll: this.assignAll ? 1 : 0,
            important: this.important ? 1 : 0,
            color: this.color ? this.color : "",
            assignments: this.assignments
        };
        if(this.id > 0) data.id = this.id;
        return data;
    };
    Agenda.prototype.setAssignAll = function(){
        if(!this.assignAll){
            this.assignAll = true;
            this.assignments = [];
            this.update();
            Facade.flagUpdated(this);
        }
        return this;
    };
    Agenda.prototype.unsetAssignAll = function(){
        if(this.assignAll){
            this.assignAll = false;
            this.update();
            Facade.flagUpdated(this);
        }
        return this;
    };
    Agenda.prototype.addAssignment = function(id){
        if(this.assignments.indexOf(id) == -1){
            this.assignments.push(id);
            this.update();
            Facade.flagUpdated(this);
        }
        return this;
    };
    Agenda.prototype.removeAssignment = function(id){
        var index = this.assignments.indexOf(id);
        if(index != -1){
            this.assignments.splice(index, 1);
            this.update();
            Facade.flagUpdated(this);
        }
        return this;
    };
    Agenda.prototype.move = function(date, order){
        var changed = false;
        if(date){
            if(!moment.isMoment(date)) date = new moment(date);
            if(this.happen.getAjaxDate() != date.getAjaxDate()){
                this.happen = date;
                changed = true;
            }
        }
        if(this.order != order){
            this.order = order;
            changed = true;
        }
        if(changed) Facade.flagUpdated(this);
        return this;
    };
    Agenda.prototype.setTag = function (tag) {
        if(tag != this.tag && (tag == 'normal' || tag == 'done' || tag == 'postpone' || tag == 'removed')){
            this.tag = tag;
            this.update();
            Facade.flagUpdated(this);
        }
        return this;
    };
    Agenda.prototype.toggleImportant = function(){
        this.important = !this.important;
        this.update();
        Facade.flagUpdated(this);
        return this;
    };
    Agenda.prototype.setColor = function(color){
        if(color != this.color){
            this.color = color;
            this.update();
            Facade.flagUpdated(this);
        }
        return this;
    };
    Agenda.prototype.setComment = function(comment){
        if(comment != this.comment){
            this.comment = comment;
            this.update();
            Facade.flagUpdated(this);
        }
        return this;
    };
    Agenda.prototype.isManaged = function(){
        return Facade.admin || this.assignments.indexOf(Facade.user) >= 0;
    };
    if($('body').removeClass("no-js").hasClass("main")){
        $("body > nav").remove();
        Facade.init(new moment('2017-02-25'), UID, Admin);
    }
});
